import { createContentfulClient } from '../../libs/contentful';
import { getServiceSiteFooterBanner } from '../index';
import Layout from '../../components/common/Layout';
import Meta from '../../components/common/Meta';
import Breadcrumb from '../../components/common/Breadcrumb';
import Kv from '../../components/section/404/Kv';
import Main from '../../components/section/404/Main';
import Careers from '../../components/section/common/Careers';

export default function Custom404({ serviceSiteFooterBanner }) {
    let breadcrumbItems = [
        {
            key: 1,
            text: 'CRISP TOP',
            href: '/',
        },
        {
            key: 2,
            text: '404 PAGE NOT FOUND',
            href: '/404',
        },
    ];

    return (
        <Layout>
            <Meta
                title="404 PAGE NOT FOUND"
                description="おっと！リンクが切れてしまったのか、このページにはもうあなたの求めるコンテンツは無くなってしまったようです…"
            />
            <Breadcrumb items={breadcrumbItems} />
            <Kv />
            <Main />
            <Careers serviceSiteFooterBanner={serviceSiteFooterBanner} />
        </Layout>
    );
}

export const getStaticProps = async () => {
    const client = createContentfulClient();
    const serviceSiteFooterBanner = await getServiceSiteFooterBanner(client);
    return {
        props: {
            serviceSiteFooterBanner,
        },
    };
};
