import { memo } from 'react';
import styles from '../../../styles/components/section/404/Kv.module.scss';

const Kv = memo(() => {
    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <svg
                    className={styles.svg}
                    viewBox="0 0 1600 563"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M197.314 448.429C321.18 448.429 421.629 351.105 421.629 224.214C421.629 97.3238 321.18 0 197.314 0C73.4484 0 -27 97.3238 -27 224.214C-27 351.105 73.4484 448.429 197.314 448.429ZM197.314 352.337C127.062 352.337 71.5997 301.211 71.5997 224.214C71.5997 147.218 127.062 96.0919 197.314 96.0919C267.567 96.0919 323.029 147.218 323.029 224.214C323.029 301.211 267.567 352.337 197.314 352.337Z"
                        fill="#0C3B2C"
                    />
                    <path
                        d="M619.908 448.429C710.496 448.429 783.214 377.592 783.214 285.812C783.214 194.032 710.496 123.195 619.908 123.195C529.319 123.195 456.602 194.032 456.602 285.812C456.602 377.592 529.319 448.429 619.908 448.429ZM619.908 358.497C579.852 358.497 549.039 329.546 549.039 285.812C549.039 242.078 579.852 213.127 619.908 213.127C659.964 213.127 690.776 242.078 690.776 285.812C690.776 329.546 659.964 358.497 619.908 358.497Z"
                        fill="#0C3B2C"
                    />
                    <path
                        d="M1017.7 123.195C976.414 123.195 946.218 137.362 925.881 160.769V131.818H833.444V563H925.881V410.854C946.218 434.262 976.414 448.429 1017.7 448.429C1098.43 448.429 1164.99 377.592 1164.99 285.812C1164.99 194.032 1098.43 123.195 1017.7 123.195ZM999.215 360.961C956.694 360.961 925.881 332.01 925.881 285.812C925.881 239.614 956.694 210.663 999.215 210.663C1041.74 210.663 1072.55 239.614 1072.55 285.812C1072.55 332.01 1041.74 360.961 999.215 360.961Z"
                        fill="#0C3B2C"
                    />
                    <path
                        d="M1307.1 221.751C1307.1 210.663 1316.96 204.503 1332.98 204.503C1353.32 204.503 1365.65 215.591 1374.27 231.606L1453.15 189.104C1427.27 145.37 1382.9 123.195 1332.98 123.195C1268.89 123.195 1212.2 157.073 1212.2 224.214C1212.2 330.778 1363.8 312.915 1363.8 346.793C1363.8 359.113 1352.7 365.888 1330.52 365.888C1303.4 365.888 1286.15 352.953 1278.14 329.546L1198.03 375.128C1222.06 425.022 1268.89 448.429 1330.52 448.429C1397.07 448.429 1458.7 418.246 1458.7 347.409C1458.7 233.454 1307.1 256.861 1307.1 221.751Z"
                        fill="#0C3B2C"
                    />
                    <path
                        d="M1531.48 285.812H1605.43L1623.92 131.818V8.62363H1512.99V131.818L1531.48 285.812ZM1568.46 448.429C1600.5 448.429 1627 421.942 1627 389.911C1627 357.881 1600.5 331.394 1568.46 331.394C1536.41 331.394 1509.91 357.881 1509.91 389.911C1509.91 421.942 1536.41 448.429 1568.46 448.429Z"
                        fill="#0C3B2C"
                    />
                </svg>
                <div className={styles.content}>
                    <h1 className={styles.contentTitle}>404 PAGE NOT FOUND</h1>
                    <p className={styles.contentText}>
                        ふむ、リンクが切れてしまったのか、このページにはもうあなたの求めるコンテンツは無くなってしまったようです…。
                    </p>
                    <p className={styles.contentText}>
                        しかし、そんなにがっかりしなくても大丈夫。そういう時はサラダでも食べて気分を変えるのがよいかと。そうそう、私はいつもこのページにたどり着くたびにHIP
                        STARをカスタムして注文することにしていましてね…。
                    </p>
                    <p className={styles.contentText}>
                        さぁ、次はあなたがカスタムする番ですよ ─ 。
                    </p>
                </div>
            </div>
        </div>
    );
});

Kv.displayName = 'Kv';

export default Kv;
