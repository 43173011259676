import Link from 'next/link';
import { memo, useEffect, useRef, useState } from 'react';
import useUserAgent from '../../../hooks/useUserAgent';
import styles from '../../../styles/components/section/404/Main.module.scss';

const Main = memo(() => {
    const [imgExtension, setImgExtension] = useState('jpg');
    const imgRef = useRef();
    useEffect(() => {
        imgRef.current.addEventListener('mouseenter', () => {
            setImgExtension('gif');
        });
        imgRef.current.addEventListener('mouseleave', () => {
            setImgExtension('jpg');
        });
    }, []);
    const { device } = useUserAgent();

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.img} ref={imgRef}>
                    <picture>
                        <source
                            media="(max-width:768px)"
                            srcSet={`/image/404/sp/main.${imgExtension}`}
                        />
                        <img
                            className={styles.imgInside}
                            src={`/image/404/main.${imgExtension}`}
                            alt="NOW, IT'S YOUR TURN!"
                            loading="lazy"
                        />
                    </picture>
                </div>
                <div className={styles.content}>
                    <h2 className={styles.contentTitle}>
                        NOW, IT&apos;S YOUR TURN!
                    </h2>
                    {/* <div className={styles.contentInfo}>
                        <span className={styles.contentInfoInside}>
                            1,295 YEN
                        </span>
                        <span className={styles.contentInfoInside}>
                            428 Kcal
                        </span>
                    </div> */}
                    <p className={styles.contentText}>
                        CRISPのデザイナー、SAJIさんはTHE
                        HIPSTARをベースに超絶カスタム。ホワイトチーズ、赤キャベツ、セロリにグリルドコーンという、目にも鮮やかなフレッシュサラダがあなたを404
                        NOT FOUNDの世界へと誘う。あなたはどうする ─？
                    </p>
                    {device === 'deviceMobile' ? (
                        <a
                            className={`${styles.contentLink} js-hover js-hover__btn__key`}
                            href="https://crisp.page.link/MMgN"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span
                                className={`${styles.contentLinkText} js-hover__btn-text`}
                            >
                                PERFECT ORDER NOW!
                            </span>
                        </a>
                    ) : (
                        <Link href="/">
                            <a
                                className={`${styles.contentLink} js-hover js-hover__btn__key`}
                            >
                                <span
                                    className={`${styles.contentLinkText} js-hover__btn-text`}
                                >
                                    BACK TO TOP
                                </span>
                            </a>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
});

Main.displayName = 'Main';

export default Main;
